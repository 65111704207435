import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: false,
  });
}

export function longFormat(date: Date): string {
  return moment(date).format('dddd, MMMM DD, YYYY, h:mm a');
}

export function parseShortFormat(date: string): string {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  });
}

export function shortFormat(date: Date): string {
  return moment(date).format('MMM DD, yyyy');
}

export function shortHourFormat(date: Date): string {
  return format(date, 'h:mm aa');
}
export function getAge(birthDate: Date) {
  return birthDate ? Math.trunc(Math.abs(Date.now() - birthDate.getTime()) / 31557600000) : null;
}

export function getTimeSince(date: Date) {
  const days = getDaysSince(date);
  if (days > 365) return `${getYearsSince(date)} years ago`;
  if (days > 31) return `${getMonthsSince(date)} months ago`;
  return `${days} days ago`;
}

export function getTimeSince1(date: Date) {
  const days = getDaysSince(date) * -1;

  if (days < 31) return `in ${days} days`;
  if (days > 365) return `in ${getYearsSince(date) * -1} years`;
  if (days > 31) return `in ${getMonthsSince(date) * -1} months`;
}

export function getDaysSince(date: Date) {
  const today = moment();
  return today.diff(date, 'days');
}

export function getMonthsSince(date: Date) {
  const today = moment();
  return today.diff(date, 'months');
}

export function getYearsSince(date: Date) {
  const today = moment();
  return today.diff(date, 'years');
}

export function fDateLink(date: Date | string | number | undefined) {
  if (date === undefined) return null;
  return format(new Date(date), 'MM/dd/yyyy');
}

export function getAgeYearsAndMonths(date: any) {
  const today = moment();
  const birthDate = moment(date);
  const yearsDiff = today.diff(birthDate, 'years');

  if (yearsDiff >= 1) {
    const monthsDiffM = today.diff(birthDate, 'months');
    if (monthsDiffM % 12 === 0) {
      return yearsDiff + ' year(s) ';
    } else {
      return (yearsDiff - 1) + ' year(s), ' + ((yearsDiff + 1) * 12 - monthsDiffM) + ' month(s)';
    }
  } else {
    const monthsDiff = today.diff(birthDate, 'months');
    return monthsDiff + ' month(s)';
  }
}
export function fSearchPatient(fecha: any) {
  try {
    // Crear un objeto Date a partir de la cadena de fecha
    var fechaObjeto = new Date(fecha);

    // Obtener los componentes de la fecha (año, mes y día)
    var año = fechaObjeto.getFullYear();
    var mes = fechaObjeto.getMonth() + 1;
    var dia = fechaObjeto.getDate();

    // Formatear la fecha en el formato deseado (yyyy-mm-dd)
    var fechaFormateada = año + '-' + mes.toString().padStart(2, '0') + '-' + dia.toString().padStart(2, '0');

    return fechaFormateada;
  } catch (error) {
    // La fecha no tiene el formato esperado
    return "Formato de fecha inválido. Utilice el formato yyyy-mm-dd.";
  }
}
export function formatAge(date: Date) {
  const today = moment();

  const yearsDiff = today.diff(date, 'years') !== 0 ? `${today.diff(date, 'years')} yr,` : '';
  const monthsDiff = (today.diff(date, 'months') % 12) !== 0 ? `${today.diff(date, 'months') % 12} mo,` : '';
  const daysDiff = (today.diff(date, 'days') % 30) !== 0 ? `${(today.diff(date, 'days') % 30)} d` : ''; 
 
  return `${yearsDiff} ${monthsDiff} ${daysDiff}`;
}

export function dateYearFormatWithAge(stringDate: string) {
  if (stringDate === undefined) {
    return "";
  }

  return `${moment.utc(stringDate).format('ll')} (${formatAge(new Date(stringDate))})`;
}

export function getAgeFromString(stringDate: string): number {
  var today = new Date();
  var birthDate = new Date(stringDate);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}