import { useEffect, useState } from 'react';
import useRegistry from './useRegistry';
import { getTenantCodeFromCareflowToken } from 'src/utils/auth-utils';
import { defaultSettings } from 'src/config';
import { SettingsValueProps } from 'src/components/settings/type';
import { Coding } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export enum BannerInfoName {
  MRN = 'patientMRN',
  AGE = 'patientAge',
  GENDER = 'patientGender',
  PRIMARY_PHONE = 'patientPrimaryPhone',
  PRIMARY_EMAIL = 'patientPrimaryEmail',
  MEDICAID = 'patientMedicaId',
  PRIMARY_ZIPCODE = 'patientPrimaryZipCode',
  SIL_SCORE = 'patientSILScore',
}

export interface BannerData {
  order: number;
  name: BannerInfoName;
}

export interface TenantThemeConfig {
  logo: string;
  tenantName: string;
  pagePrefix: string;
  tenantFavicon: string;
  tenantSpinnerLogo: string;
  url?: string;
  settings: SettingsValueProps;
}

export interface CareTeamTenantConfigData {
  defaultValues?: {
    category?: Coding;
    status?: string;
  };
  readOnly?: string[];
}

export interface WorkflowDisposition {
  showProviderWindow?: boolean;
}
export interface TenantConfigData {
  languageResource?: string | null;
  theme?: TenantThemeConfig;
  componentsData: {
    showSILInformation?: boolean;
    showOptInStatus?: boolean;
    showSubGoals?: {
      includeSubGoals: boolean,
      noOfSubGrids: number
    },
    episodeOfCare?: {
      defaultValues?: {
        type?: Coding;
      };
    };
    careTeam?: CareTeamTenantConfigData;
    practitioner?: {
      providerDirectoryURL?: string;
    };
    workflowDisposition?: WorkflowDisposition;
  };
}

export interface TenantConfigDataResponse {
  configData: TenantConfigData;
}

export const initialTenantConfigData: TenantConfigData = {
  languageResource: null,
  theme: {
    tenantName: 'MyCareCircle',
    logo: '/logo/my_care_circle_horizontal_logo.png',
    pagePrefix: 'MyCareCircle - ',
    tenantFavicon: '/favicon/MyCareCircle-Icon.png',
    tenantSpinnerLogo: '/logo/my_care_circle_horizontal_logo.png',
    settings: defaultSettings,
  },
  componentsData: {
    showSILInformation: false,
    showOptInStatus: false,
  },
};

const useTenantConfigData = (): TenantConfigData => {
  const [tenantConfigData, setTenantConfigData] =
    useState<TenantConfigData>(initialTenantConfigData);
  const { data: registry } = useRegistry({ filter: { keyRegistry: 'tenant-config-data' } });

  useEffect(() => {
    const userTenantCode = getTenantCodeFromCareflowToken();
    if (!registry?.[0] || !userTenantCode) return;
    try {
      const tenantConfig = JSON.parse(registry[0].keyValue) as TenantConfigDataResponse[];
      if (tenantConfig) setTenantConfigData({ ...initialTenantConfigData, ...tenantConfig });
    } catch (error) {}
  }, [registry]);

  return tenantConfigData;
};

export default useTenantConfigData;
